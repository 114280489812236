import "../styles/Addroom.css"
import { useState, useEffect, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import {storage} from "../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from 'firebase/storage';
import { useNavigate } from "react-router-dom";
import PlainNavbar from "./User/PlainNavbar";
import {
  useJsApiLoader,
  useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import {SkeletonText} from '@chakra-ui/react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import zIndex from "@mui/material/styles/zIndex";
import axiosInstance from "../apis/backend"

function EditRoom() {
  const {uID, roomId} = useParams()
  const [roomDetails, setRoomDetails] = useState([])
  const [isDragging, setIsDragging] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    apartment: '',
    address: '',
    price: '',
    from: '',
    to: '',
    desc: ''
});
  const [files, setFiles] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [progress, setProgress] = useState([]);
  const [address, setAddress] = useState();
  const navigate = useNavigate();

  const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyBa9IaEPXg4jOFa7o6c40E5J0KdIzzTo78",
      libraries: ["places"],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    console.log(formData)
  };
  // Function to standardize image format
  const formatImage = (image) => {
    if (typeof image === 'string') {
        return { url: image };
    } else if (image && image.url) {
        return image;
    } else {
        return { url: '' };
    }
  };

  useEffect(() => {
    const getRoomDetails = async () => {
        try {
            const res = await axiosInstance.get(`/room-desc/${roomId}`);
            setFormData({
                apartment: res.data.apartment,
                address: res.data.address,
                price: res.data.price,
                from: res.data.from,
                to: res.data.to,
                desc: res.data.desc
            });
            
            // Format existing images
            const formattedImages = res.data.img.map(formatImage);
            setImages(formattedImages);
            setImgUrls(res.data.img || []);
        } catch (error) {
            console.error('Error fetching room details:', error);
        }
    };
    getRoomDetails();
  }, [roomId])

  useEffect(() => {
      const uploadFiles = () => {
          files.forEach((file, index) => {
              const name = new Date().getTime() + file.name;
              const storageRef = ref(storage, name);
              const uploadTask = uploadBytesResumable(storageRef, file);

              uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      setProgress((prevProgress) => {
                          const newProgress = [...prevProgress];
                          newProgress[index] = progress;
                          return newProgress;
                      });
                      switch (snapshot.state) {
                          case "paused":
                              console.log("Upload is Paused");
                              break;
                          case "running":
                              console.log("Upload is Running");
                              break;
                          default:
                              break;
                      }
                  },
                  (error) => {
                      console.log(error);
                  },
                  () => {
                      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                          setImgUrls((prevImgUrls) => [...prevImgUrls, downloadURL]);
                      });
                  }
              );
          });
      };

      if (files.length > 0) {
          uploadFiles();
      }
  }, [files]);

  const selectFiles = () => {
      fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
      const files = Array.from(event.target.files);

      if (files.length === 0) return;
      const newImages = files.filter((file) => file.type.split('/')[0] === 'image');
      setFiles(newImages);
      newImages.forEach((file) => {
          if (!images.some((e) => e.name === file.name)) {
              setImages((prevImages) => [
                  ...prevImages,
                  {
                      name: file.name,
                      url: URL.createObjectURL(file),
                  },
              ]);
          }
      });
  };

  const deleteImage = (index) => {
      // Remove image from the displayed images
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));

    // Remove the file from the files state (if needed)
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    setSelectedFiles(updatedFiles);

    // Remove image URL from imgUrls state
    const updatedImgUrls = [...imgUrls];
    updatedImgUrls.splice(index, 1);
    setImgUrls(updatedImgUrls);

  };

  const onDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
      event.dataTransfer.dropEffect = "copy";
  };

  const onDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);
      const files = Array.from(event.dataTransfer.files);
      const newImages = files.filter((file) => file.type.split('/')[0] === 'image');
      setFiles((prevFiles) => [...prevFiles, ...newImages]);
      newImages.forEach((file) => {
          if (!images.some((e) => e.name === file.name)) {
              setImages((prevImages) => [
                  ...prevImages,
                  {
                      name: file.name,
                      url: URL.createObjectURL(file),
                  },
              ]);
          }
      });
  };

  const onDragLeave = (event) => {
      event.preventDefault();
      setIsDragging(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const listing = { 
        ...formData, 
        img: imgUrls 
    };
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;

    axiosInstance.put(`/updateListing/${uID}/${roomId}`, listing)
        .then(() => {
            navigate('/')
        })
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
    <PlainNavbar/>
      <div className="create">
          <h2>Edit Posting</h2>
          <form onSubmit={handleSubmit}>
              <label>Apartment Name</label>
              <input 
                  type="text" 
                  name="apartment" 
                  required 
                  value={formData.apartment}
                  onChange={handleInputChange}
              />
              <label>Address </label>
              <div><PlacesAutocomplete setAddress={(address) => setFormData((prevData) => ({ ...prevData, address }))} initialAddress={formData.address.address} /></div>
              <label>Price per month</label>
              <input
                  type="number"
                  name="price"
                  required
                  value={formData.price}
                  onChange={handleInputChange}
              />
              <label>From</label>
              <input
                  type="date"
                  name="from"
                  required
                  value={formData.from}
                  onChange={handleInputChange}
              />
              <label>To</label>
              <input
                  type="date"
                  name="to"
                  required
                  value={formData.to}
                  onChange={handleInputChange}
              />
              <label>Contact me</label>
              <textarea
                  name="desc"
                  required
                  value={formData.desc}
                  placeholder="How does the person contact you? eg: email, phone number, social media etc."
                  onChange={handleInputChange}
              ></textarea>
              <div>
                  <div className='card'>
                      <div className='top'>
                          <p>Image Uploading</p>
                      </div>
                      <div className='drag-area' onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                          {isDragging ? (
                              <span className='select'>Drop images here</span>
                          ) : (
                              <>
                                  Drag & Drop images here or (" ")
                                  <span className='select' role='button' onClick={selectFiles}>
                                      Browse
                                  </span>
                              </>
                          )}
                          <input name='file' type='file' className='file' multiple ref={fileInputRef} onChange={onFileSelect}></input>
                      </div>
                      <div className='image-container'>
                          {
                              images.map((image, index) => (
                                  <div className='image' key={index}>
                                      <span className='delete' onClick={() => deleteImage(index)}>&times;</span>
                                      <img src={image.url} alt={image.name} />
                                  </div>
                              ))
                          }
                      </div>
                  </div>
              </div>
              <button>Update Posting</button>
          </form>      
      </div>
    </div>
  );
}



const PlacesAutocomplete = ({ setAddress, initialAddress }) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    // Initialize value if initialAddress is provided
    useEffect(() => {
        if (initialAddress) {
            setValue(initialAddress, false);
        }
    }, [initialAddress, setValue]);

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setAddress({address, lat, lng}); // Only pass the address string
    };

    return (
        <div>
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className="combobox-input"
                placeholder="Enter address."
            />
            {status === "OK" && (
                <ul className="pop">
                    {data.map(({ place_id, description }) => (
                        <li key={place_id} onClick={() => handleSelect(description)}>
                            {description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};


export default EditRoom;
