import React, { useContext, useEffect, useState } from "react";
import {useParams} from "react-router-dom"
import axios from "axios";


import{
    APIProvider,
    Map,
    AdvancedMarker,
    Pin,
    InfoWindow,
} from "@vis.gl/react-google-maps";
//"use client";
import {
    GoogleMap,
    Marker,
    useLoadScript,   
  } from '@react-google-maps/api'
import axiosInstance from "../apis/backend"
import PlainNavbar from "./User/PlainNavbar";
import "../styles/Map.css"
import { collection, doc, setDoc, updateDoc, arrayUnion, serverTimestamp,getDoc } from "firebase/firestore";
import { db } from '../firebase'; // Import your Firestore instance


function Maps(){
    const params = useParams()
    const [roomInfo, setRoomInfo] = useState({});
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [position, setPosition] = useState({})
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [messageBox, setMessageBox] = useState("Hey, is this room still available?")
    const [isSending, setIsSending] = useState(true)
    const [savedRooms, setSavedRooms] = useState([]);
    const [currSaved, setCurrSaved] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
          const res = await axiosInstance.get(
            `/room-desc/${params.roomID}`
          );
          setRoomInfo({...res.data})
          setPosition({address: res.data.address.address, lat: res.data.address.lat, lng: res.data.address.lng})
          console.log(roomInfo)
        };
        fetchData();
        const getSavedRooms = async () => {
            const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
            const uID = userFromLocalStorage ? userFromLocalStorage.uid : null;
            try {
              if (!uID) {
                console.error("User ID is missing. Cannot fetch saved rooms.");
                return;
              }
        
              const res = await axiosInstance.get(`/getSavedRooms/${uID}`);
              setSavedRooms(res.data);
            } catch (error) {
              if (error.response) {
                console.error('Server Error:', error.response.status, error.response.data);
              } else if (error.request) {
                console.error('Network Error:', error.message);
              } else {
                console.error('Error:', error.message);
              }
            }
          };
          getSavedRooms();
          
      }, []);


      useEffect(() => {
        setCurrSaved(savedRooms.includes(params.roomID));
    }, [savedRooms, params.roomID]);


    useEffect(() => {
        const checkExistingConversation = async () => {
          // Get user ID from localStorage
          const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
          const userID = userFromLocalStorage ? userFromLocalStorage.uid : null;
    
          if (!userID || !roomInfo.uid) {
            console.error("User ID or Room Info is missing.");
            return;
          }
    
          try {
            // Reference to userChats collection, where id is the userID
            const userChatsRef = doc(db, "userchats", userID);
            const userChatsSnapshot = await getDoc(userChatsRef);
    
            if (userChatsSnapshot.exists()) {
              const userChatsData = userChatsSnapshot.data().chats;
    
              // Loop through chats to check for existing conversation with receiverId === roomInfo.uid
              const existingConversation = userChatsData.some(
                (chat) => chat.receiverId === roomInfo.uid
              );
    
              if (existingConversation) {
                // Set isSending to false if a conversation exists
                setIsSending(false);
              }
            }
          } catch (error) {
            console.error("Error checking existing conversation:", error);
          }
        };
    
        checkExistingConversation();
      }, [isSending, roomInfo.uid]); // Re-run if isSending or roomInfo.uid changes


      const handleSave = () => {
        setCurrSaved(!currSaved)
        const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
        const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;
        const updateSavedRooms = async () => {
          const res = await axiosInstance.get(
            `/saveRoom/${uid}/${params.roomID}`
          );          
        }
        updateSavedRooms()
      }
      
    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? roomInfo.img.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === roomInfo.img.length - 1 ? 0 : prevIndex + 1
        );
    };
    
    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    const handleMessageBoxChange = (e) => {
        setMessageBox(e.target.value)
    }

    const handleAddUserChat = async () => {
        const chatRef = collection(db, "chats");
        const userChatsRef = collection(db, "userchats");
    
        const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
        const userID = userFromLocalStorage ? userFromLocalStorage.uid : null;
    
        if (!userID) {
            console.error("User ID is missing. Cannot add user chat.");
            return;
        }
    
        const receiverId = roomInfo.uid; // Replace with the actual owner ID
    
        try {
            // Create a new chat document with initial message
            const newChatRef = doc(chatRef); // Create a new document reference for the chat
            const newChatId = newChatRef.id; // Get the document ID for the new chat
    
            await setDoc(newChatRef, {
                createdAt: Date.now(),
                messages: [{ 
                    senderId: userID, 
                    text: messageBox, 
                    timestamp: Date.now()
                }],
            });
    
            // Ensure user chat documents exist and update them
            const userChatDocRef = doc(userChatsRef, userID);
            const receiverChatDocRef = doc(userChatsRef, receiverId);
    
            // Fetch and update user chat document
            const userChatDoc = await getDoc(userChatDocRef);
            if (!userChatDoc.exists()) {
                await setDoc(userChatDocRef, {
                    chats: []
                });
            }
    
            // Fetch and update receiver chat document
            const receiverChatDoc = await getDoc(receiverChatDocRef);
            if (!receiverChatDoc.exists()) {
                await setDoc(receiverChatDocRef, {
                    chats: []
                });
            }
    
            // Update userChats collection for both users
            await updateDoc(userChatDocRef, {
                chats: arrayUnion({
                    chatId: newChatId,
                    lastMessage: messageBox,
                    receiverId: receiverId,
                    updatedAt: new Date(),
                }),
            });
    
            await updateDoc(receiverChatDocRef, {
                chats: arrayUnion({
                    chatId: newChatId,
                    lastMessage: messageBox,
                    receiverId: userID,
                    updatedAt: new Date(),
                }),
            });
    
            // Clear the message input box
            setMessageBox("");
            setIsSending(false)
        } catch (err) {
            console.error("Error adding user chat:", err);
        }
    };
    

    return (
        <div>
        <PlainNavbar/>
        <div className="map-container">
            <div className="first-section">
                <div className="map-section">
                    {position.lat && position.lng && (
                        <APIProvider apiKey="AIzaSyBa9IaEPXg4jOFa7o6c40E5J0KdIzzTo78">
                        <Map
                            zoom={18}
                            center={{ lat: position.lat, lng: position.lng }}
                            mapId="90e32c63633aacbd"
                            className="map"
                        >
                            <AdvancedMarker position={{ lat: position.lat, lng: position.lng }} zIndex={1000}>
                                <Pin
                                    background={"red"}
                                    borderColor={"black"}
                                    scale={1}
                                />
                            </AdvancedMarker>
                        </Map>
                        </APIProvider>

                    )}
                </div>
                <div className="info-section">
                <div className="section-header">
                <h2 className="section-title">
                    <span style={{ textDecoration: roomInfo.isDeleted ? 'line-through' : 'none' }}>
                        {roomInfo.apartment}
                    </span>
                    {roomInfo.isDeleted && (
                        <span style={{ color: 'red', marginLeft: '10px' }}>[DELETED]</span>
                    )}
                </h2>

                <button className="save-button" onClick={handleSave}>
                {currSaved ? (
                
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="save-icon" style={{ color: "white"}} onClick={handleSave}>
                    <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z" clip-rule="evenodd" />
                    </svg>
                  
                  
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="save-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
                    </svg>       
                )
              }    
                
                Save
                </button>
                 </div>
                    {roomInfo.img && roomInfo.img.length > 0 ? (
                        <>
                            <div className="carousel-container">  
                                <button className="carousel-button prev" onClick={handlePrevClick}>
                                    &lt;
                                </button>
                                {roomInfo.img && roomInfo.img.length > 0 && (
                                        <div className="carousel">
                                            <img
                                                src={roomInfo.img[currentImageIndex]}
                                                alt="Apartment"
                                                className="carousel-image"
                                            />  
                                        </div>
                                )}
                                <button className="carousel-button next" onClick={handleNextClick}>
                                    &gt;
                                </button>
                            </div>
                            <div className="carousel-thumbnails">
                                {roomInfo.img && roomInfo.img.length > 0 && (
                                    <div className="thumbnails-container">
                                        {roomInfo.img.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Thumbnail ${index}`}
                                                className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div>No Images Uploaded</div>
                    )}
                    <div className="message-box">
                        <div className="message-input-container">
                            <input type="text" className="message-input" value={messageBox} onChange={handleMessageBoxChange} placeholder="Write a message..." />
                            <button className="send-button" onClick={handleAddUserChat} disabled={!isSending}>
                                {isSending ? "Send Message to Owner" : "Chat Exists"}
                            </button>
                        </div>
                    </div>
                    <div className="info-grid">
                        <p><strong>Price per month:</strong> ${roomInfo.price}</p>
                        <p><strong>Address:</strong> {position.address}</p>
                        <p><strong>Dates Available:</strong> {roomInfo.from} until {roomInfo.to}</p>
                        <p><strong>Contact me:</strong> {roomInfo.desc}</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );

}
export default Maps