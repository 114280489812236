import { create } from "zustand";
import { useUserStore } from "./userStore";

export const useChatStore = create((set) => ({
  chatId: null,
  user: null,

  changeChat: (chatId, user) => {
    // You no longer need to check if users are blocked.
    // Just set the current chat and user.
    set({
      chatId,
      user,
    });
  },

  resetChat: () => {
    set({
      chatId: null,
      user: null,
    });
  },
}));
