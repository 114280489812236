import {
  AppBar,
  Box,
  styled,
  Toolbar,
  Typography,
  InputBase,
  Grid,
  Container,
  Dialog,
  Button,
  Menu,
  MenuItem,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat'; // For messaging icon
import CloseIcon from '@mui/icons-material/Close';
import RoomCard from "./RoomCard";
import MenuIcon from '@mui/icons-material/Menu';
import FilterListIcon from "@mui/icons-material/FilterList";
import "../styles/Navbar.css";
import { AuthContext } from "../contexts/AuthContext";
import { auth } from "../firebase";
import { Navigate, Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import axiosInstance from "../apis/backend";
import Chat from "./Chat/Chat";
import Logo from '../images/logo.png';  
import ChatList from "./Chat/ChatList";

import {db} from "../firebase"
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

const Icons = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
}));

const MessagingPopup = ( {userChats}) => {
  const [open, setOpen] = useState(false);

  // Open the modal when the chat icon is clicked
  const handleOpen = () => {
    setOpen(true);
    console.log("Message Box:", userChats[0].chats)
  };

  // Close the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Floating Action Button (Chat Icon) */}
      <Fab 
        color="primary"
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000,
        }}
        onClick={handleOpen}
      >
        <ChatIcon />
      </Fab>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
  <Box p={3} display="flex" flexDirection="column" style={{ height: '100%', overflow: 'hidden' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography variant="h6" style={{ fontFamily: "Outfit" }}>
        Inbox
      </Typography>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>

    {/* Chats List */}
    <Box 
      style={{
        height: 'calc(100% - 56px)', // Adjust height based on header height (56px for Dialog title and buttons)
        overflowY: 'auto', // Add vertical scrollbar if needed
      }}
    >
      <ChatList />
    </Box>
  </Box>
</Dialog>
    </>
  );
};

const Navbar = () => {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [savedRooms, setSavedRooms] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [address, setAddress] = useState("");
  const { currentUser, dispatch } = useContext(AuthContext);
  const [profileBar, setProfileBar] = useState(false);

  const [userChats, setUserChats] = useState(null); // New state for user chats
  

  const showSidebar = () => setSidebar(!sidebar);
  const showProfileBar = () => setProfileBar(!profileBar);
  const [anchorEl, setAnchorEl] = useState(null); 
  const getUserInitials = (name) => {
    if (!name) return "P";
    const names = name.split(' ');
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
  };

  const userInitials = getUserInitials(currentUser?.displayName);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  

  useEffect(() => {
    const fetchUserChats = () => {
      const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
      const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;

      if (!uid) {
        console.error("User ID is missing. Cannot fetch user chats.");
        return;
      }

      // Reference the specific document in the `userchats` collection where document ID is the same as `uid`
      const userChatsRef = doc(db, "userchats", uid);
      
      // Real-time listener
      const unsubscribe = onSnapshot(userChatsRef, (docSnap) => {
        if (docSnap.exists()) {
          // Document exists
          const chats = { id: docSnap.id, ...docSnap.data() };
          // Update state with the fetched chat document
          setUserChats([chats]); // Wrap in array since `userChats` is an array state
          console.log("User chat:", chats);
        } else {
          // Document does not exist
          console.log("No such document!");
        }
      }, (error) => {
        console.error("Error fetching user chats:", error);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    };

    fetchUserChats();
  }, []);
  
  
  useEffect(() => {
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;
    
    const getSavedRooms = async () => {
      try {
        if (!uid) {
          console.error("User ID is missing. Cannot fetch saved rooms.");
          return;
        }
  
        const res = await axiosInstance.get(`/getSavedRooms/${uid}`);
        setSavedRooms(res.data);
      } catch (error) {
        if (error.response) {
          console.error('Server Error:', error.response.status, error.response.data);
        } else if (error.request) {
          console.error('Network Error:', error.message);
        } else {
          console.error('Error:', error.message);
        }
      }
    };
  
    getSavedRooms();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance.get(
        `?q=${query}&minPrice=${minPrice}&maxPrice=${maxPrice}&from=${from}&to=${to}`
      );
      setFilteredData(res.data)

    };
    fetchData();
    
  }, [query, minPrice, maxPrice, from, to]);

  
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" }); 
    auth
      .signOut(currentUser)
      .then(() => {
        console.log("Logged out");
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT", payload: null });
        <Navigate to="/login" />;
      })
      .catch((error) => {
        console.log("There is an error");
      });
    
  };
  
  return (
    <>
      <AppBar position="sticky" className="appbar">
        <StyledToolbar>
          <Box display="flex" alignItems="center">
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "50px", height: "50px", borderRadius: "5px" }}
              />
            </Link>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Typography className='sublease-title' variant="h6" style={{ marginLeft: '10px', fontFamily: 'Outfit', color: '#f5f5f5' }}>

                SUBLEASE

              </Typography>
            </Link>
          </Box>
          <div className="navbar-search">
            <FilterListIcon
              onClick={showSidebar}
              style={{ marginRight: "10px" }}
            />
            <Search>
              <InputBase
                placeholder="search..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </Search>
          </div>
            <Box display="flex" alignItems="center">
            
            <Box display="flex" alignItems="center" style={{ marginRight: '15px' }}>
              <Typography
                onClick={handleMenuClick}
                style={{
                  color: '#f5f5f5',
                  fontFamily: 'Outfit',
                  fontSize: '14px',
                  cursor: 'pointer',
                  marginRight: '0px'
                }}
              >
                INFO
              </Typography>
              <KeyboardArrowDownIcon
                onClick={handleMenuClick}
                style={{ color: '#f5f5f5', cursor: 'pointer' }} 
              />
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/about" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Outfit' }}>About</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/feedback" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Outfit' }}>Give Us Feedback</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/contribute" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Outfit' }}>Contribute to Project</Link>
              </MenuItem>
            </Menu>
            <Box
              style={{
                width: "40px", 
                height: "40px", 
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid #4CAF50", 
                fontSize: "16px", 
                color: "#4CAF50", 
                fontFamily: 'Outfit',
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => setProfileBar(!profileBar)}
            >
              {userInitials}
            </Box>
          </Box>
          
         

          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items">
              <div className="price-container">
                <Typography style={{ fontFamily: "Outfit", color: "black" }}>
                  PRICE RANGE:{" "}
                </Typography>
                <div className="price-minmax-container">
                  <InputBase
                    placeholder="Min"
                    onChange={(e) => setMinPrice(e.target.value)}
                    className="min-container"
                  />
                  <InputBase
                    placeholder="Max"
                    onChange={(e) => setMaxPrice(e.target.value)}
                    className="max-container"
                  />
                </div>
              </div>
              <div className="date-container">
                <Typography style={{ fontFamily: "Outfit", color: "black" }}>
                  DATE RANGE:{" "}
                </Typography>
                <div className="date-fromto-container">
                  <InputBase
                    type="date"
                    placeholder="From"
                    onChange={(e) => setFrom(e.target.value)}
                    className="min-container"
                  />
                  <InputBase
                    type="date"
                    placeholder="To"
                    onChange={(e) => setTo(e.target.value)}
                    className="max-container"
                  />
                </div>
              </div>
            </ul>
          </nav>
          <nav className={profileBar ? "profile-menu active" : "profile-menu"}>
            <ul className="profile-menu-items">
              <div className="price-container">
                <Link to="/user/account-settings"><Button style={{ fontFamily: "Outfit", color: "black" }} >
                  {currentUser.displayName}
                </Button></Link>
                <Button
                  style={{ fontFamily: "Outfit", color: "black" }}
                  onClick={handleLogout}
                >
                  Logout
                  <LogoutIcon/>
                </Button>
                <Link to="/addroom"><Button style={{ fontFamily: "Outfit", color: "black" }}>
                  Add Room
                </Button></Link>
              </div>
            </ul>
          </nav>
        </StyledToolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={5}
          style={{ marginTop: "10px", marginBottom: "10px" , justifyContent:"center"}}
        >
          {filteredData && filteredData.map((item, index) => (
            <Grid 
              item 
              xs={12}         
              sm={6}          
              md={6}          
              lg={4}          
              style={{ display: 'flex', justifyContent: 'center' }}
              key={index}>
              
              {savedRooms.includes(item.id) ? (
                <RoomCard item={item} saved={true} style={{ width: '100%', maxWidth: '400px' }}/>
                ) : (
                <RoomCard item={item} saved={false} style={{ width: '100%', maxWidth: '400px' }}/>          
                )
              }    
            </Grid>
          ))}
        </Grid>
      </Container>
      {userChats && <MessagingPopup userChats = {userChats}/>}
    </>
  );
};

export default Navbar;
