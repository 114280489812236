import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import {db} from '../firebase.js'
import {collection, addDoc, getDocs, doc, getDoc, setDoc} from 'firebase/firestore'
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Link as MuiLink,
} from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import '../styles/Login.css'
import { getAuth, signInWithPopup, GoogleAuthProvider,signInWithRedirect,getRedirectResult } from "firebase/auth";



const LoginForm = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);
  const handleGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
  
    try {
      // Sign in with Google
      const result = await signInWithPopup(auth, provider);
  
      // Get user information
      const user = result.user;
      const userID = user.uid;
      const userEmail = user.email;
  
      // Check if user document exists in Firestore
      const userDocRef = doc(db, "users", userID);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        // If user does not exist, create a new document
        await setDoc(userDocRef, {
          firstname: user.displayName?.split(' ')[0] || '',
          lastname: user.displayName?.split(' ')[1] || '',
          email: userEmail,
          listings: [],
          saved_rooms: []
        });
      } else {
        // If user exists, you can update or do nothing
        console.log('User already exists in Firestore.');
      }
  
      // Dispatch the user object to your AuthContext
      dispatch({ type: "LOGIN", payload: user });
      navigate("/");
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData?.email; // Use optional chaining to avoid accessing properties of undefined
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Google sign-in error", { errorCode, errorMessage, email, credential });
    }
  };
  

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch({ type: "LOGIN", payload: user });
        navigate("/");
      })
      .catch((error) => {
        setError(true);
      });
      
  };

  return (
    <div className="container">
      
      <div className="login">
      <Typography variant="h4" align="center" gutterBottom style={{ fontFamily: "Outfit", color: "black" }}>
  Welcome to Bulls Sublease!
</Typography>
<Typography variant="body1" align="center" gutterBottom style={{ fontFamily: "Outfit", color: "black" }}>
  Sign in to get started.
</Typography>








        <Typography variant="h5" align="left" gutterBottom style={{ fontFamily: "Outfit", color: "black" }}>
          Login
        </Typography>
        <form onSubmit={handleLogin} className="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth 
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          
          <Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" style={{fontFamily: "Outfit", backgroundColor:"green"}}>
            Login
          </Button>
          <Box display="flex" justifyContent="center" alignItems="center" padding="10px">
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={handleGoogle}
            sx={{
              textTransform: 'none',
              color: 'black',
              borderColor: 'black',
              borderRadius: '50px',
              padding: '10px 20px',
              fontWeight: 'bold',
              fontSize: '16px',
              width: '100%',
              maxWidth: '300px',
              backgroundColor: 'white',
              '&:hover': {
                borderColor: 'black',
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            Sign in with Google
          </Button>
    </Box>
          <br></br> 
          <Grid container justifyContent="space-between" style={{marginTop: "10px"}}>
            <Grid item>
              <MuiLink component={Link} to="/signup" variant="body2" style={{fontFamily: "Outfit"}}>
                Don't have an account? Sign Up
              </MuiLink>
            </Grid>
            <Grid item>
              <MuiLink component={Link} to="/forgot-password" variant="body2" style={{fontFamily: "Outfit"}}>
                Forgot Password?
              </MuiLink>
            </Grid>
          </Grid>
          </Grid>
            {error && (
              <Typography className="error-message" variant="body2">
                Incorrect email or password. Please try again.
              </Typography>
            )}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
