import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Start from "./pages/Start";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import Profile from "./pages/Profile";
import { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { Users } from "./user";
import Addroom from "./pages/AddRoom"
import EditRoom from "./pages/EditRoom"
import Maps from "./pages/Map"
import UserProfile from "./pages/User/UserProfile";
import DeleteRoom from "./pages/DeleteRoom";
import ForgotPassword from "./pages/User/ForgotPassword";
import About from "./pages/About";
import Feedback from "./pages/Feedback";
import Contribute from "./pages/Contribute";

function App() {
  const { currentUser } = useContext(AuthContext);
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
          <Route
            index
            path="/"
            element={
              <RequireAuth>
                <Start />
              </RequireAuth>
            }
          />
          <Route path="/about" element={<About/>}></Route>
          <Route path="/feedback" element={<Feedback/>}></Route>
          <Route path="/contribute" element={<Contribute/>}></Route>
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile inputs={Users} />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/addroom"
            element={
              <RequireAuth>
                <Addroom />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/:uID/:roomId/editroom"
            element={
              <RequireAuth>
                <EditRoom />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/:roomId/deleteroom"
            element={
              <RequireAuth>
                <DeleteRoom
                 />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/room-desc/:roomID"
            element={
              <RequireAuth>
                <Maps />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/user/:activePage"
            element={
              <RequireAuth>
                <UserProfile />
              </RequireAuth>
            }
          ></Route>
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
