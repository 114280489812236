import "../styles/Addroom.css"
import { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import {storage} from "../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from 'firebase/storage';
import { useNavigate } from "react-router-dom";
import PlainNavbar from "./User/PlainNavbar";
import {
  useJsApiLoader,
  useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import {SkeletonText} from '@chakra-ui/react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import zIndex from "@mui/material/styles/zIndex";
import axiosInstance from "../apis/backend"

function Addroom() {
  const [isDragging, setIsDragging] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState(false);
  const fileInputRef = useRef(null);
  const [apartment, setApartment] = useState('');
  const [desc, setDesc] = useState('');
  const [price, setPrice] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [files, setFiles] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [progress, setProgress] = useState([]);
  const [address, setAddress] = useState();
  const [isAddressValid, setIsAddressValid] = useState(true);
  const navigate = useNavigate();


  const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyBa9IaEPXg4jOFa7o6c40E5J0KdIzzTo78",
      libraries: ["places"],
  });

  useEffect(() => {
      const uploadFiles = () => {
          files.forEach((file, index) => {
              const name = new Date().getTime() + file.name;
              const storageRef = ref(storage, name);
              const uploadTask = uploadBytesResumable(storageRef, file);

              uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      setProgress((prevProgress) => {
                          const newProgress = [...prevProgress];
                          newProgress[index] = progress;
                          return newProgress;
                      });
                      switch (snapshot.state) {
                          case "paused":
                              console.log("Upload is Paused");
                              break;
                          case "running":
                              console.log("Upload is Running");
                              break;
                          default:
                              break;
                      }
                  },
                  (error) => {
                      console.log(error);
                  },
                  () => {
                      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                          setImgUrls((prevImgUrls) => [...prevImgUrls, downloadURL]);
                      });
                  }
              );
          });
      };

      if (files.length > 0) {
          uploadFiles();
      }
  }, [files]);

  const selectFiles = () => {
      fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
      const files = Array.from(event.target.files);

      if (files.length === 0) return;
      const newImages = files.filter((file) => file.type.split('/')[0] === 'image');
      setFiles(newImages);
      newImages.forEach((file) => {
          if (!images.some((e) => e.name === file.name)) {
              setImages((prevImages) => [
                  ...prevImages,
                  {
                      name: file.name,
                      url: URL.createObjectURL(file),
                  },
              ]);
          }
      });
  };

  const deleteImage = (index) => {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));

      const updatedFiles = [...files];
      updatedFiles.splice(index, 1);
      setFiles(updatedFiles);
      setSelectedFiles(updatedFiles);
  };

  const onDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
      event.dataTransfer.dropEffect = "copy";
  };

  const onDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);
      const files = Array.from(event.dataTransfer.files);
      const newImages = files.filter((file) => file.type.split('/')[0] === 'image');
      setFiles((prevFiles) => [...prevFiles, ...newImages]);
      newImages.forEach((file) => {
          if (!images.some((e) => e.name === file.name)) {
              setImages((prevImages) => [
                  ...prevImages,
                  {
                      name: file.name,
                      url: URL.createObjectURL(file),
                  },
              ]);
          }
      });
  };

  const onDragLeave = (event) => {
      event.preventDefault();
      setIsDragging(false);
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      if (address == null) {
        setIsAddressValid(false);
        return;
      }
      
      const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
      const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;
      const listing = { uid: uid, apartment, address, desc, price, from, to, img: imgUrls ,isDeleted:false};

      axiosInstance.post(`/addRoom/${uid}`, listing)
          .then(() => {
              console.log('New room added');
              navigate('/');
          })
          .catch(error => {
              console.error('Error:', error);
          });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
    <PlainNavbar/>
      <div className="create">
        
          <h2>Add a New Posting</h2>
          <form onSubmit={handleSubmit}>
              <label>Apartment Name</label>
              <input 
                  type="text" 
                  required 
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
              />
              <label>Address </label>
              <div><PlacesAutocomplete setAddress={setAddress}/></div>
              <label>Price per month</label>
              <input
                  type="number"
                  required
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
              />
              <label>From</label>
              <input
                  type="date"
                  required
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
              />
              <label>To</label>
              <input
                  type="date"
                  required
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
              />
              <label>Contact me</label>
              <textarea
                  required
                  value={desc}
                  placeholder="How does the person contact you? eg: email, phone number, social media etc."
                  onChange={(e) => setDesc(e.target.value)}
              ></textarea>
             
              <div>
                  <div className='card'>
                      <div className='top'>
                          <p>Upload images of your apartment.</p>
                      </div>
                      <div className='drag-area' onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                          {isDragging ? (
                              <span className='select'>Drop images here</span>
                          ) : (
                              <>
                                  Drag & Drop images here or (" ")
                                  <span className='select' role='button' onClick={selectFiles}>
                                      Browse
                                  </span>
                              </>
                          )}
                          <input name='file' type='file' className='file' multiple ref={fileInputRef} onChange={onFileSelect}></input>
                      </div>
                      <div className='image-container'>
                          {
                              images.map((image, index) => (
                                  <div className='image' key={index}>
                                      <span className='delete' onClick={() => deleteImage(index)}>&times;</span>
                                      <img src={image.url} alt={image.name} />
                                  </div>
                              ))
                          }
                      </div>
                      {message && <div>Upload successful</div>}
                  </div>
              </div>
              {!isAddressValid && <span style={{ color: 'red' }}>Address is required</span>}
              <button>Add Posting</button>
          </form>      
      </div>
    </div>
  );
}



const PlacesAutocomplete=({setAddress})=>{
  const {
    ready,
    value,
    setValue,
    suggestions:{status, data},
    clearSuggestions,
  }= usePlacesAutocomplete();

  const handleSelect= async (address) =>{
    setValue(address, false)
    clearSuggestions()
    const results= await getGeocode({address})
    const {lat,lng} = await getLatLng(results[0])
    setAddress({address,lat,lng})
  }
  return (
    <div>
      <input 
        type="text" 
        value={value} 
        onChange={(e) => setValue(e.target.value)} 
        disabled={!ready} 
        className="combobox-input" 
        placeholder="Enter address."
      />
      {status === "OK" && (
        <ul className="pop">
          {data.map(({ place_id, description }) => (
            <li key={place_id} onClick={() => handleSelect(description)}>
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
} 


export default Addroom;
