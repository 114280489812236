import { useEffect, useState } from "react";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useChatStore } from "./chatStore";
import Chat from "./Chat"; // Import Chat component here
import { Box } from "@mui/material"; // Import Box from Material-UI
import "./chatList.css";

const ChatList = () => {
  const [userChats, setUserChats] = useState([]); // State to store user chats with user details
  const [selectedChat, setSelectedChat] = useState(null); // State to store selected chat
  const [hideEverything, setHideEverything] = useState(false);
  const { changeChat } = useChatStore();

  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;

  // Helper function to extract initials from fullName
  const getInitials = (name) => {
    if (!name) return "U"; // Fallback to 'U' if no name is available
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.slice(0, 2).toUpperCase(); // Return first two initials
  };

  // Fetch user details by userId (firstname and lastname)
  const fetchUserDetails = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userSnap = await getDoc(userDocRef);

    if (userSnap.exists()) {
      const { firstname, lastname } = userSnap.data();
      return `${firstname} ${lastname}`;
    }
    return "User"; // Fallback if the user doesn't exist or there is no name
  };

  useEffect(() => {
    if (!uid) return; // Guard clause for when uid is not available

    // Listen for changes in the "userchats" document with the same id as the uid
    const unSub = onSnapshot(doc(db, "userchats", uid), async (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data && data.chats) {
          // For each chat, fetch the user's firstname and lastname
          const chatDataWithNames = await Promise.all(
            data.chats.map(async (chat) => {
              const fullName = await fetchUserDetails(chat.receiverId);
              return { ...chat, fullName }; // Add fullName (firstname + lastname) to the chat object
            })
          );
          setUserChats(chatDataWithNames); // Store the chats with names in userChats state
        }
      }
    });

    return () => {
      unSub(); // Unsubscribe when component unmounts
    };
  }, [uid]);

  const handleSelect = (chat) => {
    setSelectedChat(chat); // Set the selected chat
    changeChat(chat.chatId, chat.user);
    setHideEverything(true); // Hide everything else
  };

  const handleBack = () => {
    setSelectedChat(null); // Deselect the chat
    setHideEverything(false); // Show the chat list
  };

  return (
    <div className="chatList">
      {hideEverything ? (
        <div className="chatContainer">
          <button className="backButton" onClick={handleBack}>Back to Inbox Messages</button>
          <div className="chatWrapper">
            <Chat chatId={selectedChat.chatId} otherUserID={selectedChat.receiverId} />
          </div>
        </div>
      ) : (
        <>
          {userChats.map((chat) => (
            <div
              className="item"
              key={chat.chatId}
              onClick={() => handleSelect(chat)}
              style={{
                backgroundColor: chat?.isSeen ? "transparent" : "#5183fe",
              }}
            >
              {/* Replacing the img tag with Box for initials */}
              <Box
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #4CAF50",
                  fontSize: "16px",
                  color: "#4CAF50",
                  fontFamily: 'Outfit',
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginRight: "10px" // Add margin to align it next to the texts
                }}
              >
                {getInitials(chat.fullName)}
              </Box>
              <div className="texts">
                <span>{chat.fullName || "User"}</span> {/* Display the fetched full name */}
                <p>{chat.lastMessage}</p>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ChatList;
