import React, { useEffect, useState } from 'react';
import axiosInstance from "../../apis/backend";
import { Card, CardMedia, CardContent, Typography, CardActions, Button } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { Link } from "react-router-dom";
import DefaultImage from "../../images/default_house.jpg";
import EditRoom from '../../pages/EditRoom';
import DeleteRoom from '../../pages/DeleteRoom';

const RoomCard = ({ roomId,}) => {
  
  const [item, setItem] = useState({});

  useEffect(() => {
    const fetchRoomDesc = async () => {
      try {
        const res = await axiosInstance.get(`/room-desc/${roomId}`);
        setItem(res.data);
      } catch (error) {
        console.error("Error fetching room description:", error);
      }
    };

    fetchRoomDesc();
  }, [roomId]);

  

  return (
    <Card sx={{ maxWidth: 345 }} style={{ padding: "10px", marginBottom: "30px" }}>
      <CardMedia
        component="img"
        alt=""
        height="140"
        image={item?.img || DefaultImage}
        style={{ borderRadius: "5px", objectFit: "contain" }}
      />
      <CardContent style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0", alignItems: "center" }}>
          <div>
            <Typography variant="h5" component="div" style={{ fontFamily: "Outfit" }}>${item?.price}</Typography>
          </div>
          <div>
            <Typography variant="h9" color="text.secondary" style={{ fontFamily: "Outfit" }}>
              {item?.from?.substring(5, 7)}/{item?.from?.substring(8)}/{item?.from?.substring(2, 4)} - {item?.to?.substring(5, 7)}/{item?.to?.substring(8)}/{item?.to?.substring(2, 4)}
            </Typography>
          </div>
        </CardContent>
        <CardContent style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0" }}>
          <PlaceIcon />
          <Typography variant="h6" component="div" style={{ fontFamily: "Outfit", color: "green" }}>{item?.apartment}</Typography>
        </CardContent>
      </CardContent>
      <CardContent style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0 15px 10px 15px", justifyContent: "space-between" }}>
        <Link to={`/""/${roomId}/editroom`}><Button>Edit</Button></Link>
        
      </CardContent>
      <CardContent style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0 15px 10px 15px", justifyContent: "space-between" }}>
        <Link to={`/${roomId}/deleteroom`}><Button>Delete</Button></Link>
        
      </CardContent>
    </Card>
  );
};

export default RoomCard;
