import LoginForm from "../components/LoginForm";
import PlainNavbar from "./User/PlainNavbar";
export default function Login() {
  return (
    <>
      <PlainNavbar/>
      <LoginForm />
    </>
  );
}
