import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Snackbar, Alert } from '@mui/material';
import axiosInstance from '../apis/backend'; 
import PlainNavbar from './User/PlainNavbar';
import { useNavigate } from 'react-router-dom';

const Feedback = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState(''); 
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/feedback', { name, email, message });
      console.log('Response:', response); // Log response
      if (response.status === 201) {
        setSnackbarMessage('Feedback submitted successfully!');
        setSnackbarSeverity('success');
        setName('');
        setEmail('');
        setMessage('');
        setTimeout(() => {
            navigate('/'); 
          }, 2000);
      } else {
        setSnackbarMessage('Failed to submit feedback. Please try again.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error); // Log error
      setSnackbarMessage('Failed to submit feedback. Please try again.');
      setSnackbarSeverity('error');
    }
    setOpenSnackbar(true);
  };

  return (
    <div>
    <PlainNavbar/>
    <Container maxWidth="md" style={{ padding: '30px' }}>
      <Typography variant="h4" gutterBottom>
        Provide Your Feedback
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit" style={{ marginTop: '16px' }}>
          Submit Feedback
        </Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
    </div>
  );
};

export default Feedback;
