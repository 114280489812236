import { useEffect, useRef, useState } from "react";
import "./chat.css";
import EmojiPicker from "emoji-picker-react";
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase.js";
import upload from "./upload.js";
import attachIcon from './attachIcon.png'; 
import send from "./send.png"


const Chat = ({ chatId, otherUserID }) => {
  const [chat, setChat] = useState();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [img, setImg] = useState({
    file: null,
    url: "",
  });

  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;

  const endRef = useRef(null);
  useEffect(() => {
    const markAsSeen = async () => {
      const userChatsRef = doc(db, "userchats", uid);
      const userChatsSnapshot = await getDoc(userChatsRef);
      
      if (userChatsSnapshot.exists()) {
        const userChatsData = userChatsSnapshot.data();
  
        const chatIndex = userChatsData.chats.findIndex((c) => c.chatId === chatId);
  
        if (chatIndex !== -1 && !userChatsData.chats[chatIndex].isSeen) {
          // Mark the chat as seen for the current user
          userChatsData.chats[chatIndex].isSeen = true;
          userChatsData.chats[chatIndex].updatedAt = Date.now();
  
          await updateDoc(userChatsRef, {
            chats: userChatsData.chats,
          });
        }
      }
    };
  
    if (chatId) {
      markAsSeen();
    }
  }, [chatId, uid]);
  
  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat?.messages]);

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", chatId), (res) => {
      setChat(res.data());
    });

    return () => {
      unSub();
    };
  }, [chatId]);

  const handleEmoji = (e) => {
    setText((prev) => prev + e.emoji);
    setOpen(false);
  };

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImg({
        file: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSend = async () => {
    if (text === "") return;
  
    let imgUrl = null;
  
    try {
      if (img.file) {
        imgUrl = await upload(img.file);
      }
  
      await updateDoc(doc(db, "chats", chatId), {
        messages: arrayUnion({
          senderId: uid,
          text,
          createdAt: Date.now(),  // Updated to use timestamp in milliseconds
          ...(imgUrl && { img: imgUrl }),
        }),
      });
  
      const userIDs = [uid, otherUserID];
  
      userIDs.forEach(async (id) => {
        const userChatsRef = doc(db, "userchats", id);
        const userChatsSnapshot = await getDoc(userChatsRef);
  
        if (userChatsSnapshot.exists()) {
          const userChatsData = userChatsSnapshot.data();
  
          const chatIndex = userChatsData.chats.findIndex((c) => c.chatId === chatId);
  
          if (chatIndex !== -1) {
            userChatsData.chats[chatIndex].lastMessage = text;
            userChatsData.chats[chatIndex].isSeen = id === uid ? true : false;
            userChatsData.chats[chatIndex].updatedAt = Date.now();
  
            await updateDoc(userChatsRef, {
              chats: userChatsData.chats,
            });
          }
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setImg({
        file: null,
        url: "",
      });
  
      setText("");
    }
  };
  

  return (
    <div className="chat">
      <div className="top">
        <div className="user">
          
        </div>
       
      </div>
      <div className="center">
        {chat?.messages?.map((message) => (
          <div
            className={message.senderId === uid ? "message own" : "message"}
            key={message?.createdAt}
          >
            <div className="texts">
              {message.img && <img src={message.img} alt="" />}
              <p>{message.text}</p>
              {/* Change this line to handle 'createdAt' as a timestamp */}
              <span>{new Date(message.createdAt).toLocaleString()}</span>
            </div>
          </div>
        ))}
        {img.url && (
          <div className="message own">
            <div className="texts">
              <img src={img.url} alt="" />
            </div>
          </div>
        )}
        <div ref={endRef}></div>
      </div>
      <div className="bottom">
        <div className="icons">
          <label htmlFor="file">
            <img src={attachIcon} alt="" />
          </label>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            onChange={handleImg}
          />
          
        </div>
        <input
          type="text"
          placeholder="Type a message..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div className="icons">
          
          <img src={send} alt="" onClick={handleSend} />
        </div>
      </div>
      {open && (
        <div className="emojiPicker">
          <EmojiPicker onEmojiClick={handleEmoji} />
        </div>
      )}
    </div>
  );
};

export default Chat;
