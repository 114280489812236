import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import PlainNavbar from './User/PlainNavbar';

const Contribute = () => {
  return (
    <div>
      <PlainNavbar />
      <Container maxWidth="md" style={{ padding: '30px' }}>
        <Typography variant="h4" gutterBottom>
          Contribute to Bulls Sublease
        </Typography>
        <Typography variant="body1" gutterBottom>
          Help us make Bulls Sublease better! We're always looking for ways to improve, and we'd love your help.
          You can contribute by checking out our GitHub repository and making changes to the code.
        </Typography>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            href="https://github.com/Bhuvan279/BullsSublease"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Our GitHub Repository
          </Button>
        </Box>
        <Typography variant="body1" gutterBottom>
          To contribute:
        </Typography>
        <ol>
          <li>
            <Typography variant="body1">
              Fork the repository to your own GitHub account by clicking the "Fork" button on the repository page.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Make your changes on the <strong>open-source</strong> branch.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Once you've made your changes, submit a pull request to the <strong>open-source</strong> branch of the original repository.
            </Typography>
          </li>
        </ol>
        <Typography variant="body1" gutterBottom>
          If you need any help with access or have any questions, feel free to reach out to us using the{' '}
          <a href="/feedback">Submit Feedback</a> form.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for helping us improve Bulls Sublease!
        </Typography>
      </Container>
    </div>
  );
};

export default Contribute;
