import React, { useEffect, useState, useContext } from 'react';
import './AccountSettings.css';
import {Grid, Container} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../apis/backend";
import YourListingCard from "./YourListingCard"


const YourListings = () => {
  const [postedRooms, setPostedRooms] = useState([]);

  useEffect(() => {
    const fetchPostedRooms = async () => {
      try {
        const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
        const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;

        if (uid) {
          // Fetch saved rooms
          const res = await axiosInstance.get(`/getPostedRooms/${uid}`);
          const rooms = res.data;
          setPostedRooms(rooms);
          
          
        } else {
          console.error("No user ID found in localStorage");
        }
      } catch (error) {
        console.error("Error fetching posted rooms:", error);
      }
    };

    

    fetchPostedRooms();
  }, []);

  return (
    <div className='accountsettings'>
     
      <h2>Your Posted Listings</h2>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={5}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
            {postedRooms.length > 0 ? (
        postedRooms.map((room, index) => (
          <Grid item xs={12} sm={6} ms={4} key={index}>
            {/* Render room information here */}
            <YourListingCard roomId={room}/>
          </Grid>
        ))
      ) : (
        <p>No postings found for your account.</p>
      )}
          
        </Grid>
      </Container>
      
      <br />
    </div>
  );
};

export default YourListings;
