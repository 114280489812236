import "../styles/Addroom.css"
import { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import {storage} from "../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from 'firebase/storage';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useJsApiLoader,
  useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import {SkeletonText} from '@chakra-ui/react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import zIndex from "@mui/material/styles/zIndex";
import axiosInstance from "../apis/backend"
function DeleteRoom() {
    const {roomId} = useParams();
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    const uID = userFromLocalStorage ? userFromLocalStorage.uid : null;
    const navigate = useNavigate();

    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        axiosInstance.get(`/deleteRoom/${uID}/${roomId}`)
            .then(() => {
                navigate('/user/my-houses');
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };
  
    const goBack = (e) => {
        e.preventDefault();
        navigate('/user/my-houses');
    }
    
  
    return (
        <div className="create">
            <h2>Delete Posting</h2>
            <form>
                <label>Are you sure you want to delete this posting?</label>
                <button onClick={handleSubmit}>Yes</button>
                <button onClick={goBack}>No</button>
                
                
            </form>      
        </div>
    );
  }
  export default DeleteRoom;
  