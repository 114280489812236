import React from 'react';
import { Container, Typography, Box, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png'; 
import PlainNavbar from './User/PlainNavbar';

const About = () => {
  return (
    <div>
    <PlainNavbar/>
    <Container maxWidth="md" style={{ padding: '20px', textAlign: 'center' }}>
      <Box marginBottom={4}>
        <img src={Logo} alt="Bulls Sublease Logo" style={{ width: '150px', height: 'auto', marginBottom: '20px' }} />
        <Typography variant="h3" gutterBottom>
          About Bulls Sublease
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        Bulls Sublease was started as an alternative to the overwhelming flood of posts in groups on Facebook Marketplace, WhatsApp, and Discord. We understand how challenging it can be to find a sublease or a place to stay off-campus during semesters, winter, or summer breaks. Our platform provides a streamlined and user-friendly solution to help you with your subleasing needs.
      </Typography>
      <Typography variant="body1" paragraph>
        With Bulls Sublease, you can easily search for and filter available rooms that meet your criteria. We also offer the option to set your room preferences, so you can receive email notifications whenever a room matching your preferences is posted. This ensures you never miss an opportunity to find the perfect sublease or place to stay.
      </Typography>
      <Box marginY={4}>
        <Typography variant="h6" gutterBottom>
          Features
        </Typography>
        <Divider />
        <Box marginTop={2}>
          <Typography variant="body1" paragraph>
            <strong>Search and Filter:</strong> Find available rooms based on your search criteria and filter results to find the best matches.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Room Preferences:</strong> Set your room preferences to receive email notifications when new postings match your criteria.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Streamlined Experience:</strong> Avoid the hassle of navigating multiple platforms and enjoy a dedicated space for subleasing needs.
          </Typography>
        </Box>
      </Box>
      <Box marginY={4}>
        <Typography variant="body1" paragraph>
          Start your search for a sublease or a place to stay now and experience the convenience of Bulls Sublease. <Link to="/" style={{ textDecoration: 'none', color: 'green' }}>Search Now</Link>.
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" color="primary" component={Link} to="/feedback">
          Provide Feedback
        </Button>
      </Box>
    </Container>
    </div>
  );
};

export default About;
