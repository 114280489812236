import React from 'react'
import { useParams } from 'react-router-dom'
import UserSidebar from '../../components/UserProfile/UserSidebar'
import AccountSettings from '../../components/UserProfile/AccountSettings'
import SavedHouses from '../../components/UserProfile/SavedHouses'

import YourListings from '../../components/UserProfile/YourListings'

import './UserProfile.css'
import PlainNavbar from './PlainNavbar'; 

const UserProfile = () => {

    const {activePage} = useParams()

    return (
        <div className='userprofile'>
            <PlainNavbar />
            <div className='userprofilein'>
                <div className='left'>
                    {<UserSidebar activePage={activePage}/>}
                </div>
                <div className='right'>
                    {activePage === 'account-settings' && <AccountSettings/>}
                    {activePage === 'saved-houses' && <SavedHouses/>}

                    {activePage === 'my-houses' && <YourListings/>}

                </div>

            </div>
        </div>
    )
}

export default UserProfile